// -------------------------
// --- You can add your custom CSS here and it will overwrite template styles.
// -------------------------

.image-logo {
  max-width: 40px !important;
  height: auto;

  @media (max-width: 480px) {
    max-width: 30px !important;
  }
}

.bg-accent {
  background-color: $accent-color;
  color: $light-color;
}

.text-accent {
  color: $accent-color;
}

.table-bordered th {
  &.bg-accent {
    border-color: darken($accent-color, 8%);
  }

  @media (max-width: 480px) {
    &.bg-accent {
      border-color: $accent-color;
    }
  }
}

.table {
  td,
  th {
    vertical-align: middle;
  }

  @media (max-width: 480px) {
    td,
    th {
      padding: 0.75rem 0.5rem;
    }

    .btn {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

.table thead th {
  &.bg-accent {
    border-bottom-color: darken($accent-color, 8%);
  }
}

.filter-list-mobifone__list {
  text-align: left;
  margin-left: -8px;
  margin-right: -8px;

  .filter-list__item {
    display: inline-flex;
    margin-left: 8px;
    margin-right: 8px;
    align-items: center;
  }

  img {
    max-width: 40px;
    height: auto;
  }
}

.block-finder-mobifone__header {
  padding: 20px 0;
}

.block-finder-mobifone__button {
  margin-top: 0;
}

.product-card-mobifone {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;

  &.product-card--layout--horizontal .product-card__image {
    width: 83px;
    padding: 10px 16px 9px;
  }

  .block-products-carousel[data-layout="horizontal"] & .product-card__image {
    width: 83px;
    padding: 10px 16px 9px;
  }

  img {
    max-width: 50px;
  }

  .block-products-carousel[data-layout="horizontal"] & .product-card__name,
  .product-card__name {
    font-size: 18px;
  }

  .block-products-carousel[data-layout="horizontal"] & .product-card__info,
  &.product-card--layout--horizontal .product-card__info {
    padding-top: 14px;
    padding-bottom: 20px;
    width: 100%;
    padding-left: 0;
    padding-right: 30px;
  }

  [dir="ltr"]
    .block-products-carousel[data-layout="horizontal"]
    &
    .product-card__actions,
  [dir="ltr"] &.product-card--layout--horizontal .product-card__actions {
    left: 85px;
    position: absolute;
    bottom: 18px;
  }
}

.head-mang {
  font-size: 0;

  @media (min-width: 1200px) {
    font-size: 16px;
  }
}

.shop-filter-mobile {
  display: flex;
  flex-wrap: nowrap;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1175);
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  left: 0;
  right: 0;
  background: #fff;

  .action-sort {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60px;
    cursor: pointer !important;
    transition: 0.3s;

    [class*="fa-"] {
      color: $link-color;
      transition: 0.3s;
    }

    &:hover,
    &:focus {
      color: #fff !important;
      background-color: $link-color !important;

      [class*="fa-"] {
        color: #fff;
      }
    }
  }
}

@media (min-width: 992px) {
  .block-finder--layout--with-departments .block-finder-mobifone__body {
    height: 610px;
  }

  .block-finder-mobifone__body {
    padding-bottom: 30px;
  }
}

@media (min-width: 1200px) {
  .block-finder--layout--with-departments .block-finder-mobifone__body {
    height: 490px;
  }
}

@media (max-width: 991px) {
  .block-finder-mobifone__body {
    height: auto;
  }
}

@media (max-width: 767px) {
  .block-products-carousel-mobile {
    .block-header {
      .block-header__arrow {
        display: none !important;
      }
    }
  }

  .block-finder-mobifone__body {
    padding-left: 20px;
    padding-right: 20px;
  }

  .block-products-carousel-wrapper-mobile {
    position: relative;
    padding-right: 10px;
    padding-left: 10px;

    .block-header__arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
      margin-top: -30px;
      opacity: 0.5;

      &:focus {
        opacity: 1;
      }

      &--left {
        left: -10px;
      }

      &--right {
        right: -10px;
      }
    }
  }
}
